/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-15 12:10:26
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 17:09:31
 * @FilePath: /PPTist/src/api/resource.ts
 */
import service from './http'

const Api = {
  GetResourceDetail: '/edu/chapterResource/detail',
  Update: '/edu/chapterResource/modify'
}

export const getDetail = (id: string) => service.get(`${Api.GetResourceDetail}/${id}`)

export const updateResource = (data: { id: string, value: string, field: string }) => service.put(`${Api.Update}`, data)